import React, { FC, useCallback } from "react"
import { Formik } from "formik"
import { message } from "antd"
import { Form, Input, SubmitButton } from "formik-antd"
import * as Yup from "yup"
import { parsePhoneNumberFromString } from "libphonenumber-js"
import _ from "lodash"
// import ReCAPTCHA from "react-google-recaptcha"

import { MailOutlined, UserOutlined, PhoneOutlined } from "@ant-design/icons"
import ICInput from "./data-entry/TextInput"

import { submitContactForm } from "../services/ic.service"

// define contact info schema for validation
const ContactInfoSchema = Yup.object().shape({
  name: Yup.string()
    .required("Your name is required")
    .min(5, "Invalid input, Minimum 5 characters!!!")
    .max(25, "Invalid Input. Maximum 25 characters!!!")
    .trim(),
  phone_number: Yup.string()
    .required("Phone number is required")
    .matches(/^[1-9]\d{9}$/, {
      message: "Invalid Phone Number Format",
    })
    .test("is-us-phone-number", "Invalid Phone Number", (value, context) => {
      return value && parsePhoneNumberFromString(value, "US")
        ? parsePhoneNumberFromString(value, "US").isValid()
        : true
    }),
  email: Yup.string().email("Invalid Email Address").trim(),
  message: Yup.string()
    .required("Message is required")
    .min(20, "Minimum 20 characters")
    .max(100, "Maximum 200 characters"),
})

const defaultValues = {
  name: "",
  phone_number: "",
  email: "",
  message: "",
}

const ContactUs: FC<{}> = () => {
  const handleFormSubmit = useCallback((values, actions) => {
    const payload = {
      name: _.toUpper(_.escapeRegExp(values.name)),
      phone_number: parsePhoneNumberFromString(
        values.phone_number,
        "US"
      ).formatNational(),
      email: _.escapeRegExp(values.email),
      message: _.escapeRegExp(values.message),
    }

    submitContactForm(payload)
      .then(res => {
        message.success(res.data.message)
      })
      .catch(() => message.error("Something unexpected happens!!!"))
      .finally(() => actions.resetForm())
  }, [])

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={ContactInfoSchema}
      isInitialValid={false}
      onSubmit={handleFormSubmit}
    >
      {({ isValid }) => (
        <Form layout="vertical" className="shadow rounded mx-2 mb-2 px-2 py-2">
          <ICInput
            type="text"
            name="name"
            placeholder="First, Last Name"
            label="Name"
            required
            allowClear
            prefix={<UserOutlined className="site-form-item-icon" />}
          />

          <ICInput
            type="phoneNumber"
            name="phone_number"
            placeholder=""
            label="Phone Number"
            required
            prefix={<PhoneOutlined className="site-form-item-icon" />}
          />

          <ICInput
            type="text"
            name="email"
            placeholder="example@domain.com"
            label="Email"
            prefix={<MailOutlined className="site-form-item-icon" />}
          />

          <Form.Item name="message" label="Message">
            <Input.TextArea
              name="message"
              placeholder="We love hearing from you"
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
          </Form.Item>

          <SubmitButton disabled={!isValid}>SEND</SubmitButton>
        </Form>
      )}
    </Formik>
  )
}

export default ContactUs
