import React, { FC } from "react"
import { Typography, Row, Col, Space, Button } from "antd"
import { PhoneOutlined, MailOutlined } from "@ant-design/icons"

import ContactForm from "../components/contact-form"
import Layout from "../components/layout"
import SEO from "../components/seo"

const { Title, Paragraph } = Typography

const ContactUs: FC<{}> = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <div className="bg-white py-4">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3328.3731875700387!2d-117.7021233848003!3d33.46563348076957!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcf050d2090285%3A0xa5f03ecb5d07f498!2s24625%20Del%20Prado%20Ave%2C%20Dana%20Point%2C%20CA%2092629!5e0!3m2!1sen!2sus!4v1612765912290!5m2!1sen!2sus"
          width="100%"
          height="280"
          frameBorder="0"
          style={{ border: "0" }}
          allowFullScreen
          aria-hidden="false"
          tabIndex={0}
        />
        <Title level={1} className="px-2 text-center bg-light py-2">
          CONTACT US
        </Title>

        <Row>
          <Col span={24}>
            <div className="pl-2">
              <Title level={4}>OPEN HOURS</Title>
              <Paragraph>
                TUE - SAT: <strong>9:30AM - 7:00PM</strong> <br />
                SUN: <strong>10:00AM - 6:00PM</strong>
              </Paragraph>

              <Title level={4}>CONTACT INFO</Title>

              <Space>
                <Button
                  type="primary"
                  shape="round"
                  icon={<PhoneOutlined />}
                  size={"middle"}
                  href="tel:7606839999"
                >
                  (760) 683-9999
                </Button>

                <Button
                  type="primary"
                  shape="round"
                  icon={<MailOutlined />}
                  size={"middle"}
                  href="mailto:nailloungebythesea@gmail.com"
                >
                  Email Us
                </Button>
              </Space>
            </div>

            <br />

            <ContactForm />
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default ContactUs
